import React, { useState, useEffect, useRef, useCallback } from 'react';
import * as yup from 'yup';
import axios from 'axios';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { Formik, Form, Field, useFormikContext } from 'formik';
import { Select, MenuItem, FormControl, InputLabel, Typography, TextField } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const host = process.env.REACT_APP_API_ENDPOINT;

const ErrorSummary = (props) => {
  const { errors, values } = useFormikContext();
  const errorMessages = Object.values(errors);
  const errorsKeys = Object.keys(errors);
  const valuesKeys = Object.keys(values);

  return (
      (errorsKeys.every(key => valuesKeys.includes(key)) &&
      <div style={{ margin: '1em 0', color: 'red' }}>
        {errorMessages.length > 0 && <p>Por favor, corrige los siguientes errores:</p>}
        <ul>
          {errorMessages.map((error, index) => (
            <li key={index}>{error}</li>
          ))}
        </ul>
      </div>)
  );
};

const MessageTemplateForm = ({ open, setOpen, contextUser, handleSubmit }) => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const [messageTemplates, setMessageTemplates] = React.useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [templateFields, setTemplateFields] = useState(null);
  const [initialValues, setInitialValues] = useState({});
  const [validationSchema, setValidationSchema] = useState(yup.object());
  const formikRef = useRef(null);
  const selectRef = useRef(null);


  const handleClose = () => {
    setOpen(false);
    setMessageTemplates(null);
  }

  const onSubmit = (values) => {
    handleSubmit(values, templateFields, resetValues);
    // formikRef.resetForm();
    // resetValues();
  }

  const resetValues = () => {
    setMessageTemplates(null);
    setSelectedTemplate(null);
    setTemplateFields(null);
    setInitialValues(null);
    setValidationSchema(null);
  }

  const fetchMessageTemplates = useCallback(async () => {
        try {
          const response = await axios.get(`${host}/api/messagetemplate/`);
          let templates = response.data;
          setMessageTemplates(templates);

          if (selectedTemplate === null) {
            let selectedUserMessageTemplate = templates.find(t => t.id === contextUser.messageTemplateId);

            if (selectedUserMessageTemplate !== undefined && selectedTemplate !== selectedUserMessageTemplate) {
              setSelectedTemplate(selectedUserMessageTemplate);
            }
          }
        } catch (error) {
          console.error('Failed to fetch message templates:', error);
        }
      }, [selectedTemplate, contextUser.messageTemplateId]);

      const fetchTemplateFields = useCallback(async () => {
            try {
              if (selectedTemplate) {
                const response = await axios.get(`${host}/api/messagetemplatefield/${contextUser.email}/${selectedTemplate.id}`);
                let data = response.data;
                if (!templateFields || JSON.stringify(templateFields.sort((a, b) => a.id.localeCompare(b.id))) !== JSON.stringify(data.sort((a, b) => a.id.localeCompare(b.id)))) {
                  setTemplateFields(data);

                  if (formikRef.current && initialValues && validationSchema) {
                    formikRef.current.resetForm();
                  }
                }
              }
            } catch (error) {
              console.error('Failed to fetch template fields:', error);
            }
          }, [selectedTemplate, templateFields, initialValues, validationSchema, contextUser.email]);

  useEffect(() => {
    if (!messageTemplates) {
      fetchMessageTemplates();
    }
  }, [messageTemplates, contextUser.messageTemplateId, fetchMessageTemplates]);

  useEffect(() => {
    if (selectedTemplate) {
      fetchTemplateFields();
    }
  }, [selectedTemplate, contextUser.email, templateFields, fetchTemplateFields]);



  // useEffect(() => {
  //   console.log("bk 1");
  //   const fetchMessageTemplates = async () => {
  //     try {
  //       const response = await axios.get(`${host}/api/messagetemplate/`);
  //       let templates = response.data;
  //       setMessageTemplates(templates);

  //       if (selectedTemplate === null) {
  //         let selectedUserMessageTemplate = templates.find(t => t.id === contextUser.messageTemplateId);

  //         if (selectedUserMessageTemplate !== undefined) {
  //           setSelectedTemplate(selectedUserMessageTemplate);
  //         }
  //       }
  //     } catch (error) {
  //       console.error('Failed to fetch message templates:', error);
  //     }
  //   };

  //   const fetchTemplateFields = async () => {
  //     try {
  //       if (selectedTemplate) {
  //         const response = await axios.get(`${host}/api/messagetemplatefield/${contextUser.email}/${selectedTemplate.id}`);
  //         let data = response.data;
  //         if (JSON.stringify(templateFields) !== JSON.stringify(data)) {
  //           setTemplateFields(data);

  //           if (formikRef.current && initialValues && validationSchema) {
  //             formikRef.current.resetForm();
  //           }
  //         }
  //       }
  //     } catch (error) {
  //       console.error('Failed to fetch template fields:', error);
  //     }
  //   };

  //   if (!messageTemplates) {
  //     fetchMessageTemplates();
  //   }

  //   if (!selectedTemplate) {
  //     fetchTemplateFields();
  //   }

  //   return () => {
  //     source.cancel('Operation canceled by the user.');
  //   };
  // }, [
  //   messageTemplates,
  //   contextUser.messageTemplateId,
  //   contextUser.email,
  //   selectedTemplate,
  //   initialValues,
  //   validationSchema,
  //   templateFields,
  //   source]);



  // useEffect(() => {
  //   console.log("bk 2");
  //   const fetchTemplateFields = async () => {
  //     try {
  //       if (selectedTemplate) {
  //         const response = await axios.get(`${host}/api/messagetemplatefield/${contextUser.email}/${selectedTemplate.id}`);
  //         let data = response.data;
  //         if (JSON.stringify(templateFields) !== JSON.stringify(data)) {
  //           setTemplateFields(data);

  //           if (formikRef.current && initialValues && validationSchema) {
  //             formikRef.current.resetForm();
  //           }
  //         }
  //       }
  //     } catch (error) {
  //       console.error('Failed to fetch template fields:', error);
  //     }
  //   };

  //   fetchTemplateFields();

  //   return () => {
  //     source.cancel('Operation canceled by the user.');
  //   };
  // }, [selectedTemplate, contextUser.email, initialValues, validationSchema, templateFields, source]);

  useEffect(() => {
    if (templateFields) {
      const createValidationSchema = () => {
        let schema = {};
        if (templateFields) {
          templateFields.forEach((field) => {
            if (field.type === 0) {
              schema[field.id] = yup.string().required(field.description + ' es requerido');
            }
          });
        }
        return yup.object().shape(schema);
      };

      const createInitialValues = () => {
        let initialValues = {};
        if (templateFields) {
          templateFields.forEach((field) => {
            initialValues[field.id] = (field.defaultValue??field.value) || '';
          });
        }
        return initialValues;
      };

      const newValidationSchema = createValidationSchema();
      const newInitialValues = createInitialValues();

      if (JSON.stringify(newValidationSchema) !== JSON.stringify(validationSchema)) {
        setValidationSchema(newValidationSchema);
      }
      if (JSON.stringify(newInitialValues) !== JSON.stringify(initialValues)) {
        setInitialValues(newInitialValues);
      }

      if (formikRef.current) {
          templateFields.forEach(field => {
            formikRef.current.setFieldValue(field.id, field.defaultValue??field.value);
          });
      }

      if (formikRef.current && initialValues && validationSchema) {
        formikRef.current.resetForm({ values: newInitialValues });
      }
    }
  }, [templateFields, initialValues, validationSchema]);


  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth={true}
      PaperProps={{
        style: {
          margin: isMobile ? '0' : '48px',
          width: isMobile ? '100%' : 'auto',
        }
      }}
      >
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle>Plantilla seleccionada</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ color:'#1976d2' }}>Este es el mensaje que estarán recibiendo tus clientes, personaliza el mensaje capturando los campos requeridos.</DialogContentText>
          <Formik
            initialValues={initialValues}
            innerRef={formikRef}
            validationSchema={validationSchema}
            onSubmit={(values) => onSubmit(values)}
          >
            {({ resetForm, setFieldValue, values, setErrors, errors }) => (
            <Form>
              {messageTemplates && (
            <FormControl fullWidth variant="outlined" margin="normal">
              <InputLabel id="template-label">Selecciona una plantilla</InputLabel>
              <Select
                labelId="template-label"
                ref={selectRef}
                value={selectedTemplate ? selectedTemplate.id : ''}
                onChange={(e) => {
                  let template = messageTemplates.find((template) => template.id === e.target.value);
                  setSelectedTemplate(template);
                  resetForm();
                }}
                label="Selecciona una plantilla"
              >
                <MenuItem value="">
                  <em>Seleccione una opción</em>
                </MenuItem>
                {messageTemplates.map((template) => (
                  <MenuItem key={template.id} value={template.id}>
                    {template.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <br />
          <div style={{ minHeight: '40vh', maxHeight: '40vh', overflowY: 'auto' }}>
            {selectedTemplate && templateFields && (
              <Typography variant="body1" gutterBottom style={{ whiteSpace: 'pre-wrap', lineHeight: '2', marginTop: '30px' }}> {/* Aquí usamos pre-wrap para respetar los espacios y saltos de línea en el string de template */}
                {templateFields
                .sort((a, b) => a.order - b.order)
                .reduce((components, field) => {
                  const parts = components[components.length - 1].split(new RegExp(`\\{${field.order}\\}`));

                  const newComponents = [
                    ...components.slice(0, components.length - 1),
                    ...parts.map((part, index) => {
                      if (index === parts.length - 1) return part;

                      return (
                        <React.Fragment key={field.name + index}>
                          {part}
                          <Field
                            as={TextField}
                            name={field.id}
                            label={field.description}
                            onChange={e => {
                              setFieldValue(field.id, e.target.value);
                            }}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                              readOnly: false,
                              inputProps: {
                                style: {
                                  fontSize: '0.9em',
                                  padding: '0.3em',
                                },
                              },
                            }}
                            variant="outlined"
                            size="small"
                            style={{
                              margin: '0.2em 0 0.2em 0',
                              backgroundColor: (field.type === 1)?'lightgray':'white'
                            }}
                            autoComplete="off"
                          />
                        </React.Fragment>
                      );
                    }),
                  ];

                  return newComponents;
                }, [selectedTemplate.template.replace(/<br>/g, '\n')])}
                </Typography>
            )}
            <ErrorSummary messageTemplate={selectedTemplate}/>
            </div>

        <DialogActions style={{ borderTop: '1px solid #ccc', padding: '8px 24px' }}>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button type="submit">Guardar</Button>
        </DialogActions>
            </Form>
            )}
          </Formik>
        </DialogContent>

    </Dialog>
  );
};

export default MessageTemplateForm;